import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.scss';
import theme from './assets/theme';
import { authConfig, config } from './config';
import ErrorBoundary from './configure/ErrorBoundary';
import GoogleAnalytics from './configure/GoogleAnalytics';
import { ConfigProvider } from './context/ConfigContext';
import { queryClient } from './queryClient';
import Routes from './routes/Routes';
import QueryStringParser from './configure/QueryStringParser';

export default function App() {
  const pca = new PublicClientApplication(authConfig);
  return (
    <RecoilRoot>
      <ConfigProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <MsalProvider instance={pca}>
              <BrowserRouter basename="/competency">
                <QueryStringParser>
                  <ErrorBoundary>
                    <GoogleAnalytics>
                      <Routes />
                    </GoogleAnalytics>
                  </ErrorBoundary>
                </QueryStringParser>
              </BrowserRouter>
            </MsalProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </RecoilRoot>
  );
}
