import {
  Image, Box, Flex, HStack,
} from '@chakra-ui/react';
import CornerFold from '@src/assets/images/fold.svg';
import HeaderLogo from './components/HeaderLogo';

export default function MainHeaderLayout({ children }) {
  return (
    <Box
      as="header"
      pos="sticky"
      w="full"
      h="80px"
      top={0}
      left={0}
      right={0}
      zIndex={3}
      backgroundColor="brand.orange.400"
    >
      <Flex wrap="wrap" align="center" px={4} height="100%">
        <HeaderLogo width={{ base: 'auto', xl: '18rem' }} />
        <HStack flex={1}>
          { children }
        </HStack>
      </Flex>
      <Image src={CornerFold} alt="Fold" position="absolute" bottom="0" right="0" />
    </Box>
  );
}
