import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
} from '@chakra-ui/react';
import { FiUser, FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useUserInfo } from '@transport-insights/uikit';

export default function UserMenu() {
  const history = useHistory();

  const { fullName, email, firstName } = useUserInfo();

  const displayUser = fullName || firstName || email || 'Unknown';

  const onSignOut = () => { history.push('/signout'); };

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icon as={FiUser} />}
        color="brand.orange.50"
        _hover={{
          color: 'brand.orange.400',
          backgroundColor: 'brand.orange.50',
        }}
        variant="ghost"
      >
        { displayUser }
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<Icon as={FiLogOut} />}
          onClick={onSignOut}
        >
          Sign Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
