import { Spacer } from '@chakra-ui/react';
import { useUserInfo } from '@transport-insights/uikit';
import MainHeaderLayout from './MainHeaderLayout';
import UserMenu from './components/UserMenu';

export default function MainHeader() {
  const { isLoggedIn } = useUserInfo();
  return (
    <MainHeaderLayout>
      <Spacer />
      {isLoggedIn && <UserMenu />}
    </MainHeaderLayout>
  );
}
