import { queryStringDatabaseIdState } from '@src/state/queryString';
import qs from 'qs';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

/*
This component is responsible for setting the selected database id from the query string
We then remove the query string from the URL to keep it looking clean
*/
export default function QueryStringParser({ children }) {
  const setSelectedDatabaseId = useSetRecoilState(queryStringDatabaseIdState);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { dbid } = qs.parse(location.search || '', { ignoreQueryPrefix: true });

    if (!dbid) {
      return;
    }
    setSelectedDatabaseId(dbid);

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('dbid');

    const newLocation = {
      ...location,
      search: searchParams.toString(),
    };
    history.replace(newLocation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}
