/* eslint-disable max-len */
import {
  Container, Heading, Link, ListItem, Text, UnorderedList,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';

export default function PrivacyPage() {
  return (
    <MainLayout>
      <Container maxW="container.lg">
        <Heading mb="4">Why are we collecting this information?</Heading>
        <Text>
          Te Ringa Maimoa is collecting this information to enable sector wide analytics and reporting of capability and capacity –
          This will involve reviewing the skills and experience of all participants compared to role expectations, and
          assessing what resources are available. Before reports are made available all personally identifiable
          information will be removed.
        </Text>

        <Heading mt="8">Privacy policy</Heading>
        <Text my="4">
          Te Ringa Maimoa complies with the New Zealand Privacy Act 2020 (the Act) when dealing with personal
          information. Personal information is information about an identifiable individual (a natural person). This
          policy sets out how Te Ringa Maimoa will collect, use, disclose and protect your personal information. This policy does
          not limit or exclude any of your rights under the Act. If you wish to seek further information on the Act, see
          {' '}
          <Link target="_blank" href="http://www.privacy.org.nz">
            http://www.privacy.org.nz.
          </Link>
        </Text>

        <Heading as="h4" size="md" mt="6" mb="4">
          Changes to this policy
        </Heading>
        <Text my="4">Te Ringa Maimoa may change this policy by uploading a revised policy onto the website.</Text>

        <Heading as="h4" size="md" mt="6" mb="4">
          Who do we collect your personal information from
        </Heading>
        <Text my="4">Te Ringa Maimoa will collect personal information about you from:</Text>
        <UnorderedList my="4">
          <ListItem>
            you, when you provide that personal information to us, including via the website and any related service,
            through any registration or subscription process, through any contact with us (e.g. telephone call or
            email).
          </ListItem>
        </UnorderedList>

        <Heading as="h4" size="md" mt="6" mb="4">
          How we use your personal information
        </Heading>
        <Text>Te Ringa Maimoa will use your personal information:</Text>
        <UnorderedList my="4">
          <ListItem>to verify your identity,</ListItem>
          <ListItem>
            to enable sector wide analytics and reporting of capability and capacity – This will involve reviewing the
            skills and experience of all participants compared to role expectations, and assessing what resources are
            available. Before reports are made available all personally identifiable information will be removed.
          </ListItem>
          <ListItem>for any other purpose authorised by you or the Act.</ListItem>
        </UnorderedList>

        <Heading as="h4" size="md" mt="6" mb="4">
          Disclosing your personal information
        </Heading>
        <Text>Te Ringa Maimoa may disclose your personal information to:</Text>
        <UnorderedList my="4">
          <ListItem>any other person authorised by the Act or another law (e.g. a law enforcement agency),</ListItem>
          <ListItem>any other person authorised by you.</ListItem>
        </UnorderedList>
        <Text>
          A business that supports our services may be located outside New Zealand. This may mean your personal
          information is held and processed outside New Zealand.
        </Text>

        <Heading as="h4" size="md" mt="6" mb="4">
          Protecting your personal information
        </Heading>
        <Text>
          Te Ringa Maimoa will take reasonable steps to keep your personal information safe from loss, unauthorised activity, or
          other misuse.
        </Text>

        <Heading as="h4" size="md" mt="6" mb="4">
          Accessing and correcting your personal information
        </Heading>
        <Text>
          Subject to certain grounds for refusal set out in the Act, you have the right to access your readily
          retrievable personal information that Te Ringa Maimoa holds and to request a correction to your personal information. You
          will be able to exercise this right via a profile management page within the platform, or contact Te Ringa Maimoa to
          undertake this on your behalf. Note that prior to making changes on your behalf Te Ringa Maimoa will need evidence to
          confirm that you are the individual to whom the personal information relates.
        </Text>

        <Heading as="h4" size="md" mt="6" mb="4">
          Contacting us
        </Heading>
        <Text>
          If you have any questions about this privacy policy, our privacy practices, or if you would like to request
          access to, or correction of, your personal information, you can contact Te Ringa Maimoa at
          {' '}
          <Link href="mailto:TeRingaMaimoa@nzta.govt.nz">TeRingaMaimoa@nzta.govt.nz.</Link>
        </Text>
      </Container>
    </MainLayout>
  );
}
