import { atom } from 'recoil';

/*
 When jumping between domains we need to know the users selected RCA (dbid)
 To do this we pass the dbid in the query string.
 This atom is used to store the dbid from the query string.
 */
const queryStringDatabaseIdState = atom({
  key: 'queryStringDatabaseId',
  // Someone may navigate to amcf directly so we need to default to 1000 here
  default: '1000',
});

export {
  queryStringDatabaseIdState,
};
