import {
  Button,
  Container,
  HStack,
  Heading, Link, Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text, VStack,
} from '@chakra-ui/react';
import {
  AMCF_PDF_DOC_URL,
  AMCF_PROJECT_PAGE_URL,
  PCF_PROJECT_PAGE_URL,
  PCF_PDF_DOC_URL,
} from '@src/assets/constants';
import SURVEY_DEFINITIONS from '@src/assets/surveys';
import MainLayout from '@src/components/layouts/main';
import { submissionStatusSelector } from '@src/state/submissionStatusSelector';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CompetencyScoreTable from '../shared/CompetencyScoreTable';

function SurveyTab({
  code, name,
}) {
  const submissionStatus = useRecoilValue(submissionStatusSelector(code));

  let surveyButtonText = 'Start survey';
  if (submissionStatus.isInProgress) {
    surveyButtonText = 'Continue survey';
  } else if (submissionStatus.hasSubmitted) {
    surveyButtonText = 'Update survey';
  }

  return (
    <VStack
      alignItems="start"
      flex={1}
      spacing="6"
      p={4}
    >
      <Heading
        as="h2"
        size="md"
        color="black"
        _hover={{
          color: 'brand.orange.400',
        }}
      >
        {name}
      </Heading>
      <HStack spacing="6">
        <Button
          as={RouterLink}
          colorScheme="gray"
          color="black"
          to={`/survey/${code}`}
        >
          {surveyButtonText}
        </Button>
        {submissionStatus.hasSubmitted && (
        <Link
          as={RouterLink}
          ml="2"
          to={`/report/${code}`}
        >
          View report
        </Link>
        )}
      </HStack>
    </VStack>
  );
}

/*
If more surveys are added in the future we could split this out into a separate component
I didn't think it made sense to add it to the SURVEY_DEFINITIONS array as it's only used
on the home page
*/
function SurveyContent({ code }) {
  if (code === 'amcf') {
    return (
      <Stack spacing="6" mt={6}>
        <Heading as="h2" size="md">
          What is the Asset Management Competency Framework?
        </Heading>
        <Text mb={2}>
          Te Ringa Maimoa recognises that individually and collectively as a sector we must ensure
          we have the right teams of appropriately skilled and experienced people to plan for and
          deliver great service to our customers.
        </Text>
        <Text mb={2}>
          The
          {' '}
          <Link href={AMCF_PROJECT_PAGE_URL} target="_blank">
            Asset Management Competency Framework (AMCF)
          </Link>
          {' '}
          seeks to recognise the importance of our human ‘assets’ and provides
          a framework for identifying appropriate
          competencies for different aspects of asset management
          and pathways for capability enhancement. It has also
          been set up in an asset agnostic manner so it will be
          able to be used across other council asset types too.
        </Text>
        <Text mb={2}>
          Asset management capability profiles can be built using a six-step assessment.
          This enables individuals and
          organisations to determine what level of competence
          is required for their organisation and for individual
          team members. For each of the questions,
          individuals are asked to rate their competence on the scale below.
          Further definition can be found in the
          {' '}
          <Link href={AMCF_PDF_DOC_URL} target="_blank">
            AMCF document.
          </Link>
        </Text>
      </Stack>
    );
  }
  if (code === 'pcf') {
    return (
      <Stack spacing="6" mt={6}>
        <Heading as="h2" size="md">
          What is the Procurement Competency Framework?
        </Heading>
        <Text mb={2}>
          Te Ringa Maimoa has developed this
          {' '}
          <Link href={PCF_PROJECT_PAGE_URL} target="_blank">
            Procurement Competency Framework
          </Link>
          {' '}
          as a complementary framework to the Te Ringa Maimoa Asset Management Competency Framework.
        </Text>
        <Text mb={2}>
          Developing this approach is important because our sector holds the mandate
          and expectations from  the public to manage activities and assets appropriately and
          in a way that allows for communities and businesses to thrive today and ensure
          sustainability in the long term.
        </Text>
        <Text mb={2}>
          Procurement capability profiles can be built using a six-step assessment.
          This enables individuals and
          organisations to determine what level of competence
          is required for their organisation and for individual
          team members. For each of the questions,
          individuals are asked to rate their competence on the scale below.
          Further definition can be found in the
          {' '}
          <Link href={PCF_PDF_DOC_URL} target="_blank">
            PCF document.
          </Link>
        </Text>
      </Stack>
    );
  }
}

export default function HomePage() {
  return (
    <MainLayout>
      <Container maxW="container.xl">
        <Tabs isFitted variant="enclosed">
          <TabList>
            {SURVEY_DEFINITIONS.map((survey) => (
              <Tab
                key={survey.code}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <SurveyTab
                  code={survey.code}
                  name={survey.name}
                />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {SURVEY_DEFINITIONS.map((survey) => (
              <TabPanel key={survey.code}>
                <SurveyContent code={survey.code} />
                <CompetencyScoreTable />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Container>
    </MainLayout>
  );
}
